import React, { FunctionComponent } from "react";
import styled from "styled-components";

import { Device } from "utils/device";
import { CM } from "components/Typography";
import { colours } from "utils";
import MarketingLink from "./MarketingLink";
import { uuid } from "utils";

type StyleProps = {
  background?: string;
  fontColour?: string;
  largeText?: boolean;
  roxborough?: boolean;
};

type QuoteContentProps = {
  author: string;
  paragraphs: string[] | React.ReactNode[];
  source: string;
  background?: string;
  fontColour?: string;
  linkTextOne?: string;
  hrefOne?: string;
  linkTextTwo?: string;
  hrefTwo?: string;
  animationColour?: string;
  largeText?: boolean;
  roxborough?: boolean;
  linkOneOpensNewTab?: boolean;
  linkTwoOpensNewTab?: boolean;
  subAuthor?: string;
  subSource?: string;
};

const QuoteContent: FunctionComponent<QuoteContentProps> = (props) => {
  return (
    <S.Section {...props}>
      <CM>
        <b>{props.author} |</b> <i>{props.source}</i>
      </CM>
      {props.paragraphs.map((paragraph) => (
        <>
          <S.Paragraph {...props} key={uuid()}>
            {paragraph}
          </S.Paragraph>
        </>
      ))}
      {props.subAuthor && (
        <CM>
          <b>{props.subAuthor} |</b> <i>{props.subSource}</i>
        </CM>
      )}
      {props.linkTextOne && (
        <S.LinksContainer>
          <MarketingLink
            href={props.hrefOne}
            colour={props.fontColour}
            textColour={props.fontColour}
            linkText={props.linkTextOne}
            animationColour={props.animationColour}
            target={props.linkOneOpensNewTab ? "_blank" : ""}
          />{" "}
          {props.linkTextTwo && (
            <MarketingLink
              href={props.hrefTwo}
              colour={props.fontColour}
              textColour={props.fontColour}
              linkText={props.linkTextTwo}
              animationColour={props.animationColour}
              target={props.linkOneOpensNewTab ? "_target" : ""}
            />
          )}
        </S.LinksContainer>
      )}
    </S.Section>
  );
};

const S = () => {};

S.Section = styled.section<StyleProps>`
  width: 100%;
  padding: 10%;
  display: flex;
  flex-direction: column;
  gap: 20px;
  color: ${(p) => p.fontColour || colours.charcoal};
  background: ${(p) => p.background || colours.marketingShell};

  @media (min-width: ${Device.tablet}) {
    padding: 10% 30% 10% 10%;
  }
`;

S.Paragraph = styled.p<StyleProps>`
  font-size: ${(p) => (p.largeText ? "2.5" : "1.5")}rem;
  ${(p) =>
    p.roxborough &&
    `
    font-family: Roxborough CF, sans-serif;
    font-weight: 100;
  `}

  @media(min-width: ${Device.mobile}) {
    font-size: ${(p) => (p.largeText ? "3.5" : "2")}rem;
  }
`;

S.LinksContainer = styled.div`
  padding: 0 0 10px 0;
  display: flex;
  gap: 40px;

  @media (min-width: ${Device.mobile}) {
    padding-top: 2rem;
  }
`;

export default QuoteContent;
